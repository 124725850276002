import React from "react";
import {Helmet} from "react-helmet";
import {Layout} from "@components";
import {TopSection} from "@templates/partner/TopSection";
import {HowWorkSection} from "@templates/partner/HowWorkSection";
import {AdvantagesPartnerSection} from "@templates/partner/AdvantagesPartnerSection";
import {HowBePartnerSection} from "@templates/partner/HowBePartnerSection";
import {WhoBecomePartnerSection} from "@templates/partner/WhoBecomePartnerSection";
import {PartnerQuizSection} from "@templates/partner/PartnerQuizSection";
import styled from "styled-components";
import {LAPTOP_MOBILE_POINTER, MOBILE_MINIMOBILE_POINTER} from "@tools/constants";
import {WhoBecomePartnerTableSection} from "@templates/partner/WhoBecomePartnerTableSection";
import {WhoBecomePartnerIndividualSection} from "@templates/partner/WhoBecomePartnerIndividualSection";
import {WhoBecomePartnerIndividualTableSection} from "@templates/partner/WhoBecomePartnerIndividualTableSection";


const PartnerPage = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>
                    Партнерская программа для агентов и компаний с самозанятыми
                </title>
                <meta
                    name="description"
                    content="Станьте партнером Самозанятые.рф: приводи новых клиентов и получай ежемесячное агентское вознаграждение."
                />
                <link
                    rel="canonical"
                    href="https://xn--80aapgyievp4gwb.xn--p1ai/partner"
                />
            </Helmet>
            <Layout disableWidth={true}>

                <MaxWidthBox>
                    <TopSection/>
                    <HowWorkSection/>
                    <AdvantagesPartnerSection/>
                    <WhoBecomePartnerSection/>
                </MaxWidthBox>

                <WhoBecomePartnerTableSection/>

                <MaxWidthBox>
                    <WhoBecomePartnerIndividualSection/>
                </MaxWidthBox>

                <WhoBecomePartnerIndividualTableSection/>

                <MaxWidthBox>
                    <HowBePartnerSection/>
                    <PartnerQuizSection/>
                </MaxWidthBox>

            </Layout>
        </>
    );
};

export default PartnerPage;

const MaxWidthBox = styled.div`

    margin: 0 auto;

    @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
        max-width: 1204px;
        padding: 0 32px;
    }
    @media (min-width: ${MOBILE_MINIMOBILE_POINTER + 1}px) and (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 609px;
        padding: 0 20px;
    }
    @media (max-width: ${MOBILE_MINIMOBILE_POINTER}px) {
        max-width: 375px;
        padding: 0 16px;
    }

`;
