import styled from "styled-components";
import React from "react";
import {TypographyUI} from "@ui";
import {useWindowSize} from "@tools/hooks";
import * as styles from "./style.module.scss";
import {AdaptiveLinkCt} from "@components";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";

const tableOrganization = {
    header: ['Оборот клиента за месяц', 'Комиссия сервиса', 'Комиссия агента', 'Размер выплаты в год'],
    col1: ['до 500 000 ₽', 'от 500 000 до 1 000 000 ₽', 'от 1 000 000 до 5 000 000 ₽', 'от 5 000 000 до 15 000 000 ₽', 'от 15 000 000 до 30 000 000 ₽', 'от 30 000 000 ₽'],
    col2: ['3,00%', '2,50%', '2,00%', '1,50%', '1,25%', '1%'],
    col3: ['0,55%', '0,45%', '0,35%', '0,20%', '0,15%', '0,10%'],
    col4: ['26 400 рублей', '37 800 рублей', '126 000 рублей', '240 000 рублей', '360 400 рублей', '480 000 рублей'],
}

export const WhoBecomePartnerTableSection = () => {

    const {width} = useWindowSize();

    return (
        <>
            <WhoBecomePartnerSectionTable>
                <WhoBecomePartnerSectionTableTitle>Сколько вы заработаете</WhoBecomePartnerSectionTableTitle>
                <WhoBecomePartnerSectionTableItems>
                    <div className={[styles.whoBecomePartnerTable, styles.whoBecomePartnerTableOrganisation].join(' ')}>
                        <div className={[styles.whoBecomePartnerTableColumn, styles.whoBecomePartnerTableColumnFirst].join(' ')}>
                            <TypographyUI.H4Bold
                                className={styles.whoBecomePartnerTableColumnHead}>{tableOrganization.header[0]}</TypographyUI.H4Bold>
                            {tableOrganization.col1.map((item, i) =>
                                (<TypographyUI.Body1Regular key={item}
                                                            className={styles.whoBecomePartnerTableColumnItem}>{item}</TypographyUI.Body1Regular>)
                            )}
                        </div>
                        <div className={styles.whoBecomePartnerTableColumn}>
                            <TypographyUI.H4Bold
                                className={styles.whoBecomePartnerTableColumnHead}>{tableOrganization.header[1]}</TypographyUI.H4Bold>
                            {tableOrganization.col2.map((item, i) =>
                                (<TypographyUI.Body1Regular key={item}
                                                            className={styles.whoBecomePartnerTableColumnItem}>{item}</TypographyUI.Body1Regular>)
                            )}
                        </div>
                        <div className={styles.whoBecomePartnerTableColumn}>
                            <TypographyUI.H4Bold
                                className={styles.whoBecomePartnerTableColumnHead}>{tableOrganization.header[2]}</TypographyUI.H4Bold>
                            {tableOrganization.col3.map((item, i) =>
                                (<TypographyUI.Body1Regular key={item}
                                                            className={styles.whoBecomePartnerTableColumnItem}>{item}</TypographyUI.Body1Regular>)
                            )}
                        </div>
                        <div className={styles.whoBecomePartnerTableColumn}>
                            <TypographyUI.H4Bold
                                className={styles.whoBecomePartnerTableColumnHead}>{tableOrganization.header[3]}</TypographyUI.H4Bold>
                            {tableOrganization.col4.map((item, i) =>
                                (<TypographyUI.Body1Regular key={item}
                                                            className={styles.whoBecomePartnerTableColumnItem}>{item}</TypographyUI.Body1Regular>)
                            )}
                        </div>
                    </div>
                    <WhoBecomePartnerSectionDesc>
                        Первую выплату вы получите на расчетный счет спустя месяц работы компании с платформой
                        <WhoBecomePartnerSectionLink to={'/business'}> «Бизнес»</WhoBecomePartnerSectionLink>
                    </WhoBecomePartnerSectionDesc>
                </WhoBecomePartnerSectionTableItems>
            </WhoBecomePartnerSectionTable>
        </>
    )

}

const WhoBecomePartnerSectionTable = styled.div`
    overflow-x: hidden;
    margin-bottom: 160px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin-bottom: 60px;
    }
`;
const WhoBecomePartnerSectionTableTitle = styled(TypographyUI.H3)`
    margin-bottom: 40px;
    text-align: center;
`;

const WhoBecomePartnerSectionDesc = styled(TypographyUI.Body1Regular)`
    max-width: 497px;
    color: var(--text-secondary);
    align-self: flex-start;
    margin-top: 40px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 497px;
        padding-left: 40px;
    }
    @media (max-width: 609px) {
        max-width: 100%;
        padding: 0 40px;
    }
`;

const WhoBecomePartnerSectionLink = styled(AdaptiveLinkCt)`
    margin-bottom: 40px;
    text-align: center;
    color: var(--accent-main);
`;

const WhoBecomePartnerSectionTableItems = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 1144px;
    margin: 0 auto;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }
`;




