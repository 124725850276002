import styled from "styled-components";
import React from "react";
import {TypographyUI} from "@ui";
import {useWindowSize} from "@tools/hooks";
import * as styles from "./style.module.scss";
import {AdaptiveLinkCt} from "@components";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";

const tableIndividual = {
    header: ['Оборот приведенного клиента за месяц', 'Ваш доход за три месяца за одного клиента'],
    col1: ['до 2 млн ₽', '2 — 5 млн ₽', '5 — 20 млн ₽', '20 — 50 млн ₽', 'от 50 млн ₽'],
    col2: ['до 50 000 ₽', 'до 100 000 ₽', 'до 150 000 ₽', 'до 200 000 ₽', 'до 250 000 ₽'],
}

export const WhoBecomePartnerIndividualTableSection = () => {

    const {width} = useWindowSize();

    return (
        <>
            <WhoBecomePartnerIndividualTable>
                <WhoBecomePartnerIndividualTableTitle>Сколько вы заработаете</WhoBecomePartnerIndividualTableTitle>
                <WhoBecomePartnerIndividualTableItems>

                    <div className={[styles.whoBecomePartnerTable, styles.whoBecomePartnerTableIndividual].join(' ')}>
                        <div className={[styles.whoBecomePartnerTableColumn, styles.whoBecomePartnerTableColumnFirst].join(' ')}>
                            <TypographyUI.H4Bold swichPoint={LAPTOP_MOBILE_POINTER}
                                className={styles.whoBecomePartnerTableColumnHead}>{tableIndividual.header[0]}</TypographyUI.H4Bold>
                            {tableIndividual.col1.map((item, i) =>
                                (<TypographyUI.Body1Regular key={item}
                                                            className={styles.whoBecomePartnerTableColumnItem}>{item}</TypographyUI.Body1Regular>)
                            )}
                        </div>
                        <div className={styles.whoBecomePartnerTableColumn}>
                            <TypographyUI.H4Bold swichPoint={LAPTOP_MOBILE_POINTER}
                                className={styles.whoBecomePartnerTableColumnHead}>{tableIndividual.header[1]}</TypographyUI.H4Bold>
                            {tableIndividual.col2.map((item, i) =>
                                (<TypographyUI.Body1Regular key={item}
                                                            className={styles.whoBecomePartnerTableColumnItem}>{item}</TypographyUI.Body1Regular>)
                            )}
                        </div>
                    </div>

                    <WhoBecomePartnerSectionDesc>
                        Единоразовую выплату вы получите спустя 3 месяца работы компании с платформой
                        <WhoBecomePartnerSectionLink to={'/business'}> «Бизнес»</WhoBecomePartnerSectionLink>
                    </WhoBecomePartnerSectionDesc>
                </WhoBecomePartnerIndividualTableItems>
            </WhoBecomePartnerIndividualTable>
        </>
    )

}

const WhoBecomePartnerIndividualTable = styled.div`
    overflow-x: hidden;
    margin-bottom: 160px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin-bottom: 60px;
    }
`;
const WhoBecomePartnerIndividualTableTitle = styled(TypographyUI.H3)`
    margin-bottom: 60px;
    text-align: center;
`;

const WhoBecomePartnerSectionDesc = styled(TypographyUI.Body1Regular)`
    max-width: 497px;
    color: var(--text-secondary);
    align-self: flex-start;
    margin-top: 40px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 497px;
        padding-left: 40px;
    }
    @media (max-width: 609px) {
        max-width: 100%;
        padding: 0 40px;
    }
`;

const WhoBecomePartnerSectionLink = styled(AdaptiveLinkCt)`
    margin-bottom: 40px;
    text-align: center;
    color: var(--accent-main);
`;

const WhoBecomePartnerIndividualTableItems = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 1144px;
    margin: 0 auto;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }
`;




